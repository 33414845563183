// Dependencies
import React, { Component } from "react";
import "./Footer.css";
import GoogleMapReact from 'google-map-react';
import marker from './pin.png';
const AnyReactComponent = ({ text }) => (
  <div>
    <img src={marker} style={{ height: '50px', width: '70px' }} />
  </div>
);



class Footer extends Component {
  constructor(){
    super()
    this.state = {
      showPhone: "false",
      center: {
        lat: 42.418640,
        lng: -88.614690
      },
      zoom: 15
    }
    }

  componentDidMount() {
    document.getElementById("button-click9").style.display = "none";
  }

  showPhone() {
    document.getElementById("mySidenav-contact").style.height = "470px";
    document.getElementById("button-click10").style.display = "block";
    document.getElementById("mySidenav-contact").style.opacity = "1";
  }

  closePhone() {
    document.getElementById("mySidenav-contact").style.height = "0";
    document.getElementById("button-click10").style.display = "none";
    document.body.style.opacity = "1";
  }

  showMap() {
    document.getElementById("mySidenav-map").style.height = "450px";
    document.getElementById("mySidenav-map").style.opacity = "1";
    document.getElementById("button-click9").style.display = "block";
    this.closePhone.bind(this);
  }

  closeMap() {
    document.getElementById("mySidenav-map").style.height = "0";
    document.getElementById("button-click9").style.display = "none";

    document.body.style.opacity = "1";
  }

  render() {
    return (
      <div>
      <div id="mySidenav-map" className="sidenav-map">
      <div className="map-line-div">
      <div id="button-click9" className="button-click"><button className="button button9" onClick={this.closeMap.bind(this)}><i className="fas fa-angle-down"></i>
      </button></div>

      {/* <a href="javascript:void(0)" className="closebtn" onClick={this.closeMap.bind(this)}>&times;</a><br/> */}
        {/* <h2 className="tag-line">ANGELO'S</h2> */}
        {/* <text className="map-address">205 E. Front Street - Harvard, Illinois</text> */}
        </div>
        <div style={{ height: '98%', width: '100%' }}>
        
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCPvCFUBc2S3vwceYseUyR3amC3vS5ltvw"}}
        defaultCenter={this.state.center}
        defaultZoom={this.state.zoom}
      >
          <AnyReactComponent
          lat={42.4202}
          lng={-88.616}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
            {/* <h2 className="tag-line">ANGELO'S</h2> */}
    </div>
    <div id="mySidenav-contact" className="sidenav-contact">
          <div className="tag-line-div">
          <h2 className="tag-line">Delivery&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;Dine-In&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;Takeout</h2></div>
          <div id="button-click10" className="button-click"><button className="button button10" onClick={this.closePhone.bind(this)}><i className="fas fa-angle-down ten"></i>
            </button></div>
          {/* <h2>P&nbsp;H&nbsp;O&nbsp;N&nbsp;E</h2> */}
          {/* <div className="phone-number-div"><a href="tel:18159436458"><text className="phone-number">1 (815) 943-6458</text></a></div><br/><br/> */}
          {/* <h2>H&nbsp;O&nbsp;U&nbsp;R&nbsp;S</h2> */}
          <div className="row">
            <div className="col-md-5">
            <div className="hours">
              <div className="week-days">Monday</div><div className="phone-number-hours">Closed</div>
              <div className="week-days">Tuesday</div><div className="phone-number-hours">4pm - 10pm</div>
              <div className="week-days">Wednesday</div><div className="phone-number-hours">4pm - 10pm</div>
              <div className="week-days">Thursday</div><div className="phone-number-hours">4pm - 10pm</div>
              <div className="week-days">Friday</div><div className="phone-number-hours">4pm - 11pm</div>
              <div className="week-days">Saturday</div><div className="phone-number-hours">4pm - 11pm</div>
              <div className="week-days">Sunday</div><div className="phone-number-hours">4pm - 10pm</div>
            </div>
            </div>
            <div className="col-md-7">
            <div>
              <h7>Angelo's Pizza & Italian Restaurant<br/>205 E. Front Street<br/>Harvard, IL  60033<br/>(815) 943-6458</h7>
            </div>
            </div>
          </div>
          {/* <a href="javascript:void(0)" className="closebtn" onClick={this.closePhone.bind(this)}>&times;</a> */}
</div>
      <div className="footer-custom">
<div className="footer-div">
        <div className="address-line">
        <text>_______</text></div><div className="footer-line"><text>_______</text></div>
        <div className="social-medias">
          <a target="_blank" href="https://www.facebook.com/angelospizzaharvardillinois/"><img src="facebook-icon.jpg" className="social-media-icons" alt="facebook"/></a>
          <a target="_blank" href="https://www.instagram.com/explore/locations/375226850/angelos-pizza/"><img src="instagram.png" id="instagram-icon" className="social-media-icons" alt="instagram"/></a>
          <img src="phone.png" id="phone-icon" className="social-media-icons" alt="instagram" onClick={this.showPhone.bind(this)}/>
        </div>
        <div onClick={this.showMap.bind(this)} className="address"><h9>205 E. Front St. - Harvard, Illinois</h9></div>
        {/* <div onClick={this.showMap.bind(this)} className="address-mobile"><h2>205 E. Front Street <br/> Harvard, IL</h2></div> */}
        </div>
        </div>
        <br/>
        <div className="footer-border-and-chef">
          {/* <img className="chef-image" alt="chef" src="chef.jpg"/><br/> */}
          <img className="chef-image" alt="chef" src="wine-barrels.jpg"/><br/>
          <img src="red.jpg" className="red-bottom-border" alt="red" />
        </div>
      </div>
  );
  }}


export default Footer;
