// import React from "react";
import React, { Component } from "react";
// import got from 'got';
import "./Navbar.css";
import Modal from 'simple-react-modal';
import Collapsible from 'react-collapsible';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { Carousel } from 'react-materialize';
import CoverFlow from 'coverflow-react';
import Slider from 'react-slick';
import { Carousel } from 'react-responsive-carousel';
import ImageGallery from 'react-image-gallery';
import "./image-gallery.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'react-time-picker'
import TimeRange from 'react-time-range';
import dateFormat from 'dateformat';
import { Buffer } from 'buffer';
import { load } from 'cheerio';
import * as cheerio from 'cheerio';
var request = require("request");
const jwt = require("jsonwebtoken");
const jwtSimple = require('jwt-simple');
const sgMail = require('@sendgrid/mail');
require("dotenv").config();


class Navbar extends Component {
  constructor(props){
    super(props)
    this.featureRef = React.createRef();
    this.changeStartHandler = this.changeStartHandler.bind(this);
    this.timeChangeHandler = this.timeChangeHandler.bind(this);
    this.changeCompleteHandler = this.changeCompleteHandler.bind(this);
    this.menuSelect = this.menuSelect.bind(this);
    this.menuSelectMobile = this.menuSelectMobile.bind(this);
    this.menuChoice = this.menuChoice.bind(this);
    this.dropTable = this.dropTable.bind(this);
    this.returnHome = this.returnHome.bind(this);
    this.state = {
      myArray: [],
      menuChoice: [],
      pick: "",
      homeShow: true,
      show: false,
      showII: false,
      showIII: false,
      showWelcome: false,
      showEditAccount: false,
      showReserve: false,
      data: [],
      createEmail: "",
      createPassword: "",
      confirmPassword: "",
      displayLoginRegistration: "",
      displayAddressRegistration: "",
      displayPaymentRegistration: "",
      displayConfirmationRegistration: "",
      first: "",
      last: "",
      phone: "",
      AddressOne: "",
      AddressTwo: "",
      city: "",
      state: "",
      displayLogIn: "block",
      displayLogOut: "none",
      displayCreateAccount: "block",
      displayManageAccount: "none",
      sessionFirstName: "",
      displaySessionFirst: "none",
      loginEmail: "",
      editStreetAddress: "",
      editStreetAddress2: "",
      editCity: "",
      addressEditId: "",
      displayAddressAdd: "none",
      diplayEditAddress: "block",
      defaultIndex: 0,
      deliveryDecisionScreen: "block",
      deliveryDecision: "",
      deliveryAddressDecisionScreen: "none",
      deliveryConfirmContactInfo: "none",
      addressChoiceId: "",
      addressDeliveryId: "",
      DeliveryStreetAddress: "",
      DeliveryStreetAddress2: "",
      DeliveryCity: "",
      loginScreen: "block",
      forgotPasswordScreen: "none" ,
      carousel: 1,
      roomName: "The Hornet Room",
      roomCaption: "With a fully-stocked bar & large flat-screen TV's lining every wall, the Hornet Room is the perfect venue for your Harvard graduation or birthday party.",
      roomFee: "$50/party",
      showReserveForm: "display: block",
      displayReserverForm: "none",
      startDate: new Date(),
      startTime: moment(),
      endTime: moment(),
      optionState: "The Pompeii Room"
      }
  };

  handleChange = value => this.setState({ value });

  componentDidMount =  () => {
    document.getElementById("main-food-menu").style.display = "none";
    document.getElementById("main-selection-menu").style.display = "none";
    document.getElementById("home-click").style.display = "none";
    document.getElementById("page-sub-header").style.display = "block";


    // document.getElementById("home-modal").style.display = "block";
  }

  returnHome =  () => {
    document.getElementById("main-food-menu").style.display = "none";
    document.getElementById("main-selection-menu").style.display = "none";
    document.getElementById("demo2").style.display = "block";
    document.getElementById("mobile-title-card").style.display = "block";
    if (window.innerWidth < 550) {
    document.getElementById("home-modal").style.display = "none";
    } else {
    document.getElementById("home-modal").style.display = "block";
    }
  }

  menuChoice = async (choice) => {
    document.getElementById("main-food-menu").style.display = "none";
    document.getElementById("main-selection-menu").style.display = "block";
    this.setState({pick: choice.menu_item_desc})
    this.setState({ menuChoice: []})
    try {
      if(this.state.menuChoice.length > 0) {
       } else {
    await axios.get('/sub-menu-option',{ params: {
      choice: choice.id}}).then(res => {
        for (var i = 0; i < res.data.recordset.length; i++) {
        this.setState({menuChoice: [...this.state.menuChoice, res.data.recordset[i]]})
      }
     })}} catch (error) {
      alert("Error: " + error);
     }} 

  menuSelect = async () => {
    this.closeNav();
    document.getElementById("home-modal").style.display = "none";
    document.getElementById("main-selection-menu").style.display = "none";
    this.setState({ menuChoice: []})
      try {
        if(this.state.myArray.length > 0) {
          document.getElementById("main-food-menu").style.display = "block";
          document.getElementById("home-click").style.display = "block";

         } else {
         await axios.get('/menu')
        .then(res => {
          for (var i = 0; i < res.data.recordset.length; i++) {
          this.setState({ myArray: [...this.state.myArray, res.data.recordset[i]]})
          document.getElementById("main-food-menu").style.display = "block";
          document.getElementById("home-click").style.display = "block";
        }
       })}} catch (error) {
        alert("Error " + error);
      }
    }

    menuSelectMobile = async () => {
      this.closeNav();
      // document.getElementById("mySidebar").style.display = "none";
      document.getElementById("mobile-title-card").style.display = "none";
      document.getElementById("home-modal").style.display = "none";
      document.getElementById("demo2").style.display = "none";
      document.getElementById("main-selection-menu").style.display = "none";
      document.getElementById("main-food-menu").style.display = "block";
      this.setState({ menuChoice: []})
        try {
          if(this.state.myArray.length > 0) {
           } else {
          const response = await axios.get('/menu')
          // console.log(response.data);
          .then(res => {
            for (var i = 0; i < res.data.recordset.length; i++) {
            this.setState({ myArray: [...this.state.myArray, res.data.recordset[i]]})
          }
         })}} catch (error) {
          alert("Error " + error);
        }
      }
  
  dropTable() {
    axios.get('/menu-option');
  }

  show(){
    // alert("Menu coming soon...")
    this.setState({show: false})
    this.setState({homeShow: true})
    this.setState({showII: false})
    this.setState({showIII: false})
    this.setState({showRegister: false})
    this.setState({showWelcome: false})
    this.setState({showEditAccount: false})
    this.setState({showReserve: false})
    document.getElementById("mySidebar").style.height = "0%";
  }

  closeReserve(){
    this.setState({showReserve: false})
  }

  closePhone(){
    this.setState({showPhone: false})
  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  reserve(){
    document.getElementById("party-room-sidebar").style.height = "100%";
    // document.getElementById("row").style.display = "block";
    this.setState({displayReserveForm: "none"})
    // this.setState({showReserve: true})
    // this.setState({show: false})
    // this.setState({homeShow: false})
    // this.setState({showII: false})
    // this.setState({showIII: false})
    // this.setState({showRegister: false})
    // this.setState({showWelcome: false})
    // this.setState({showEditAccount: false})
    // this.appetizers();
    // this.pizza();
    // document.getElementById("mySidebar").style.width = "0";
  }

  closeReserve(){
    document.getElementById("party-room-sidebar").style.height = "0px";
  }

    
  changeStartHandler(time){
    console.log("Start Handler Called", time);
}

timeChangeHandler(time){
    this.setState({
        value: time
    });
}

changeCompleteHandler(time){
    console.log("Complete Handler Called", time);
}
  showPhone(){
    this.setState({showPhone: true})
    document.getElementById("mySidebar").style.width = "0";
  }

  openNav() {
    document.getElementById("button-click").style.display = "none";
    document.getElementById("mobile-address-div").style.display = "none";
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar").style.height = "109%";
    document.getElementById("mySidebar").style.left = "0%";
    document.getElementById("button-click2").style.display = "block";

  }

  closeNav() {
    document.getElementById("button-click2").style.display = "none";
    document.getElementById("button-click").style.display = "block";
    document.getElementById("mySidebar").style.height = "9%";
    document.getElementById("mobile-address-div").style.display = "block";

  }

  homeShow(){
    this.setState({homeShow: true})
    this.setState({show: false})
    this.setState({showII: false})
    this.setState({showIII: false})
    this.setState({showRegister: false})
    this.setState({showWelcome: false})
    this.setState({showEditAccount: false})
    document.getElementById("mySidebar").style.width = "0";
    this.setState({showReserve: false})

  }

  close(){
    this.setState({show: false})
    this.setState({homeShow: true})
  }

  closeHome(){
    this.setState({homeShow: false})
  }
 

  showWelcome(){
    this.getAddresses();
    this.setState({showWelcome: true})
    this.setState({show: false})
    this.setState({showII: false})
    this.setState({showIII: false})
    this.setState({showRegister: false})
    this.setState({showEditAccount: false})
    this.setState({defaultIndex: 0})
    this.setState({homeShow: false})
    document.getElementById("mySidebar").style.height = "0%";
    this.setState({showReserve: false})

  }

  closeWelcome(){
    this.setState({showWelcome: false})
  }

  showAddressAdd(){
    this.setState({showEditAccount: true})
    this.setState({diplayEditAddress: "none"})
    this.setState({displayAddressAdd: "block"})
    this.setState({homeShow: false})
    this.setState({show: false})
    this.setState({showII: false})
    this.setState({showIII: false})
    this.setState({showRegister: false})
    this.setState({showWelcome: false})
    this.setState({showReserve: false})
  }

  closeAddressAdd(){
    this.setState({showWelcome: false})
  }

  showEditAccount(){
    this.setState({showEditAccount: true})
    this.setState({displayAddressAdd: "none"})
    this.setState({diplayEditAddress: "block"})
    this.setState({show: false})
    this.setState({showII: false})
    this.setState({showIII: false})
    this.setState({showRegister: false})
    this.setState({showWelcome: false})
    this.setState({homeShow: false})
    this.setState({showReserve: false})
  }

  closeEditAccount(){
    this.setState({showEditAccount: false})
  }

  showII(){
    document.getElementById("mySidebar").style.height = "0%";
    window.location.href="https://angelospizzaharvard.hungerrush.com/Account/LogOn"
  }
 
  closeII(){
    this.setState({showII: false})
  }

  showIII(){
    this.setState({showIII: true})
    this.setState({show: false})
    this.setState({showII: false})
    this.setState({showRegister: false})
    this.setState({homeShow: false})
    this.setState({showReserve: false})
  }

  closeIII(){
    this.setState({showIII: false})
    this.setState({showII: false})
    this.setState({showI: false})
    this.setState({showReserve: false})
  }

  submitReserve(){
    alert(this.refs.fname.value)
  }

  placeOrder() {
    this.closeNav();
    // document.getElementById("mySidebar").style.height = "0";
    window.location.href="https://angelospizzaharvard.hungerrush.com/Order/OrderType";
  };
  
  showRegister(){
    this.closeNav();
    // document.getElementById("mySidebar").style.height = "0%";
    window.location.href="https://angelospizzaharvard.hungerrush.com/account/register";
  }
 
  closeRegister(){
    this.setState({showRegister: false})
  }


sidenavContact() {
  // this.closeNav();
  document.getElementById("button-click2").style.display = "none";
  document.getElementById("button-click").style.display = "none";
  document.getElementById("mySidebar").style.height = "0%";
  document.getElementById("button-click7").style.display = "block";
  document.getElementById("contact-sidebar").style.height = "40%";
};

closeContact() {
  document.getElementById("button-click2").style.display = "none";
  document.getElementById("contact-sidebar").style.height = "0%";
  document.getElementById("button-click").style.display = "block";
  document.getElementById("mySidebar").style.height = "9%";
  document.getElementById("mobile-address-div").style.display = "block";

  // document.getElementById("button-click7").style.display = "none";
  // document.getElementById("button-click").style.display = "none";
  // document.getElementById("button-click2").style.display = "block";
  // document.getElementById("mySidebar").style.height = "9%";
  // document.getElementById("contact-sidebar").style.height = "0%";
  // document.getElementById("click").style.display = "block";
};

email() {
  document.getElementById("contact-sidebar").style.height = "0%";
  window.location.href="https://angelospizzaharvard.hungerrush.com/Common/ContactUs";
};


handleChange = m => {
  this.setState({ startDate: m})

}


bookPartyRoom = (event) => {
  if (this.refs.name.value === "") {
     alert("Please enter your first & last name!")
  } else if (this.refs.email.value === "") {
    alert("Please enter a valid email address!")
  } else if (this.refs.phone.value === "") {
    alert("Please enter a valid phone number!")
  } else {
  axios.post('/bookPrivateRoom', {
    partyName: this.refs.name.value,
    partyDate: this.state.startDate,
    partyPhone: this.refs.phone.value,
    partyRoom: this.state.optionState,
    partyComments: this.refs.comments.value,
    partyEmail: this.refs.email.value,
    partyStart: this.state.startTime,
    partyEnd: this.state.endTime
}).then (
  document.getElementById("party-room-sidebar").style.height = "0px",
  alert("Thank you, " + this.refs.name.value.split(' ')[0] + "!  Angelo will reach out shortly to confirm your reservation & to take down your order for food and drink for the upcoming event.  *(Payment expected upon conclusion of party)"),
  this.refs.name.value = "",
  this.refs.phone.value = "",
  this.refs.email.value = "",
  this.refs.comments.value = "",
  this.setState({ 
    startDate: new Date(),
    startTime: moment(),
    endTime: moment()})
  ).catch(function (error) {
  console.log(error);
})}};

deleteAddress = (event) => {
  var addressEditId = event.target.getAttribute('data-key');
  axios.post('/editAddress/delete', {
      addressEditId: addressEditId,
  }).then( res => {
    alert("Address has been deleted!");
    this.showWelcome();
  }).catch(function (error) {
    console.log(error);
});
};

login = (event) => {
  event.preventDefault()
  this.setState({loginEmail: this.refs.username.value.trim()})
  axios.post("/login", {
    email: this.refs.username.value,
    password: this.refs.password.value,
  })
  .then(res => {
    sessionStorage.setItem("jwt", res.data.token);
    console.log(res.data.first);
    this.setState({sessionFirstName: res.data.first})
    this.setState({displayLogIn: "none"})
    this.setState({displayLogOut: "block"})
    this.setState({deliveryDecision: ""})
    this.setState({defaultIndex: 0})
    this.setState({displayCreateAccount: "none"})
    this.setState({displaySessionFirst: "block"})
    this.setState({displayManageAccount: "block"})
    this.showWelcome();
  })
  .catch(error => {
    alert("Sorry!  Invalid email & password combination.  Please try again.")
    console.log(error);
  });
  };

  logout = (event) => {
    event.preventDefault();
    axios.post('/logout', {})
      .then((response) => {
        alert("You are now successfully logged out.");
        sessionStorage.setItem('jwt', '');
        this.setState({displayLogIn: "block"})
        this.setState({displayLogOut: "none"})
        this.setState({deliveryDecision: ""})
        this.setState({addressDeliveryId: ""})
        this.setState({data: []})
        this.setState({displaySessionFirst: "none"})
        this.setState({displayCreateAccount: "block"})
        this.setState({displayCreateAccount: "block"})
        this.setState({displayManageAccount: "none"})
        this.setState({showWelcome: false})
        this.show();
      })
      .catch(function (error) {
          console.log(error);
      })
    };

  submitNameRegistration () {
    axios.post("/submitNewMembers", {
      email: this.refs.email.value,
      password: this.refs.confirmPassword.value,
      first: this.refs.first.value,
      last: this.refs.last.value,
      phone: this.refs.phone.value,
      addressOne: this.refs.addressOne.value,
      addressTwo: this.refs.addressTwo.value,
      city: this.refs.city.value,
      state: this.refs.state.value,
    }).catch((error) => {
    alert(error.response)
      console.log(error.response);
    })};


    registrationAddressSave = (event) => {
      event.preventDefault()
      this.submitNameRegistration();
      this.setState({
        first: this.refs.first.value,
        last: this.refs.last.value,
        phone: this.refs.phone.value,
        AddressOne: this.refs.addressOne.value,
        AddressTwo: this.refs.addressTwo.value,
        city: this.refs.city.value,
        state: this.refs.state.value,
        displayLoginRegistration: "none",
        displayAddressRegistration: "none",
        displayPaymentRegistration: "block",
        displayConfirmationRegistration: "none"

      })};

      returnFunctionStart = event => {
          this.setState({ startTime: event.startTime })
        };

      returnFunctionEnd = event => {
        this.setState({ endTime: event.endTime })
        };

      registrationPaymentSave = (event) => {
        event.preventDefault()
        this.setState({
          first: this.refs.first.value,
          last: this.refs.last.value,
          phone: this.refs.phone.value,
          AddressOne: this.refs.addressOne.value,
          AddressTwo: this.refs.addressTwo.value,
          city: this.refs.city.value,
          state: this.refs.state.value,
          displayLoginRegistration: "none",
          displayAddressRegistration: "none",
          displayPaymentRegistration: "none",
          displayConfirmationRegistration: "block"
        })};

    registrationSaveLogin = (event) => {
      event.preventDefault()
      if (this.refs.createPassword.value !== this.refs.confirmPassword.value) {
        alert("Passwords Do Not Match.  Please try again.")
      } else {
      this.setState({
        createEmail: this.refs.email.value,
        createPassword: this.refs.createPassword.value,
        confirmPassword: this.refs.confirmPassword.value,
        displayLoginRegistration: "none",
        displayAddressRegistration: "block",
        displayPaymentRegistration: "none",
        displayConfirmationRegistration: "none"
      })}};
  
  pizza(){
    axios.get("/pizza") 
    .then((response) => {
      console.log(response);
      this.setState({
        data: response.data
      });
    }).catch((error) => {
      console.log(error.response);
    });}

  decisionMade (event) {
    if (event.target.getAttribute('data-key') === "delivery") {
    this.getAddresses();
    this.closeEditAccount();
    this.setState({deliveryDecisionScreen: "none"})
    this.setState({deliveryAddressDecisionScreen: "block"})
    this.setState({deliveryDecision: event.target.getAttribute('data-key')})
    } else {
      alert ("You are looking to pickup?")
    }
  }

  _onSlide(index) {
    if (index === 1) {
      this.setState({
        roomName: "The Pompeii Room",
        roomCaption: "Our most spacious private room, the Pompeii room is ideal for wedding receptions, baptisms, or anniverary parties.",
        roomFee: "$100/party",
      })} else if (index === 0) {
      this.setState({
        roomName: "The Hornet Room",
        roomCaption: "With a fully-stocked bar & large flat-screen TV's lining every wall, the Hornet Room is the perfect venue for your Harvard graduation or birthday party.",
        roomFee: "$50/party",
      })
    } else {
      this.setState({
        roomName: "The Roma Room",
        roomCaption: "Great for large family gatherings, The Roma Room brings all the elements of Italy to you and family.",
        roomFee:  "$50/party",
      })}};


  forgotPassword () {
    this.setState({loginScreen: "none"})
    this.setState({forgotPasswordScreen: "block"})  
  }

  roomSelect = event => {
    this.setState({optionState: event.target.value})
  }

  reserveForm() {
    document.getElementById("row").style.display = "none";
    alert("Please call Angelo today to reserve. PHONE: 1 (815) 943-6458");
    document.getElementById("party-room-sidebar").style.height = "0px"
  }



  

  render ()  {
    const images = [
      {
        original: 'angelos-party.jpg',
        thumbnail: 'angelos-party.jpg',
        // original: 'hornet-room.jpg',
        // thumbnail: 'hornet-room.jpg',
        originalTitle: 'The Hornet Room',
        description: 'The Hornet Room',
},
      {
        original: 'pompeii2.jpg',
        thumbnail: 'pompeii2.jpg',
        originalTitle: 'The Pompeii Room',
        description: 'The Pompeii Room',
      },
      {
        original: 'rome.jpg',
        thumbnail: 'rome.jpg',
        originalTitle: 'The Roma Room',
        description: 'The Roma Room',
            },
    ];
    return (
      // Collapsible Sidenav
      <div><div id="party-room-sidebar" className="party-room-sidebar">
      <img src="curtain.jpg" className="curtain" alt="curtain"/>
      <h5 className="sidenav-title">ANGELO'S</h5>
      <h5 className="sidenav-pizza">Banquet Rooms</h5><br/>
      <a href="javascript:void(0)" className="closebtn" onClick={this.closeReserve.bind(this)}>&times;</a>
      <div id="row"><div className="row" id="row">
        <div className="col-lg-8"><div className="reserve-slider">
      <ImageGallery thumbnailPosition={'left'} onSlide={this._onSlide.bind(this)} showNav={false} showPlayButton={false} showFullscreenButton={false} useBrowserFullscreen={false} items={images} />
      </div></div>
  <div className="col-lg-4">
      <div className="reserve-room-title"><h4 className="pompeii-title">{this.state.roomName.toUpperCase()}</h4><h6 className="reserve-fee">{this.state.roomFee}</h6><p>{this.state.roomCaption}</p></div><button onClick={this.reserveForm.bind(this)} className="reserve-party-button">B O O K</button><br/><br/><p>*extended hours available <br/>for party rooms...</p>
      </div></div></div>
      <div className="form-container"><div id="reserve-form" className="reserve-form" style={{ display: this.state.displayReserveForm }}>
          <DatePicker
          ref="date"
          selected={this.state.startDate}
          onChange={this.handleChange}
          minDate={new Date()}
          dateFormat="MMMM d, yyyy"
        /><br/>
        <TimeRange
            onStartTimeChange={this.returnFunctionStart.bind(this)}
            onEndTimeChange={this.returnFunctionEnd}
            startMoment={this.state.startTime}
            endMoment={this.state.endTime}
        />
        <input type="text" ref="name" placeholder="Full Name"/><br/>
        
        <input type="email" ref="email" placeholder="Email"/><br/>
        <input type="tel" ref="phone" placeholder="Phone Number"/><br/><br/>
        <select onChange={this.roomSelect} value={this.state.optionState}>
          <option value="The Pompeii Room">The Pompeii Room</option>
          <option value="The Hornet Room">The Hornet Room</option>
          <option value="The Roma Room">The Roma Room</option>
        </select><br/><br/>
        <textarea rows="4" cols="40" ref="comments" placeholder="Additional Comments for Angelo"/><br/>
        <button id="reserve-button" onClick={this.bookPartyRoom.bind(this)}>S U B M I T</button>
      </div>
      </div></div>

      <div className="navbar-custom">
      <img src="red.jpg" className="top-border-red" alt="red" />
     
        <div id="mySidebar" className="sidebar">
        <div id="mobile-address-div"><text className="mobile-address">205 E. Front<br/>Street</text>
        <text className="mobile-address-right">Harvard<br/>Illinois</text></div>
        <div id="button-click" className="button-click"><button className="button button5" onClick={this.openNav.bind(this)}><i className="fas fa-angle-up"></i>
        {/* <text id="click" className="sidenav-text-menu click">C L I C K<br/>H E R E</text> */}
        </button></div>

        <div className="sidenav-menu-options">
        {/* <div className="side-menu"><a onClick={this.openNav.bind(this)}><text id="click" className="sidenav-text-menu click">C L I C K&nbsp;&nbsp;&nbsp;H E R E</text></a></div><br/> */}
        <div className="actual-menu-options">
        <div id="button-click2" className="button-click2"><button className="button button6" onClick={this.closeNav.bind(this)}>
          <i className="fas fa-angle-down"></i>
          </button></div>

        <br/><br/><br/><br/>
        <div className="side-menu"><text onClick={this.menuSelectMobile.bind(this)} className="sidenav-text-menu">M&nbsp;E&nbsp;N&nbsp;U</text><br/></div><br/>
        <div className="side-menu"><text className="sidenav-text-order" onClick={this.placeOrder.bind(this)}>O&nbsp;R&nbsp;D&nbsp;E&nbsp;R</text></div><br/>
        <div className="side-menu"><div style={{ display: this.state.displayCreateAccount }} ><text className="sidenav-text-account" onClick={this.showRegister.bind(this)}>C&nbsp;R&nbsp;E&nbsp;A&nbsp;T&nbsp;E<br/>A&nbsp;C&nbsp;C&nbsp;O&nbsp;U&nbsp;N&nbsp;T</text></div></div><br/>
        <div className="side-menu-contact"><text onClick={this.sidenavContact.bind(this)} className="sidenav-text-contact">C&nbsp;O&nbsp;N&nbsp;T&nbsp;A&nbsp;C&nbsp;T</text><br/></div><br/>
        <div className="side-menu"><div style={{ display: this.state.displayLogIn }} ><text className="sidenav-text-login" onClick={this.showII.bind(this)}>L&nbsp;O&nbsp;G&nbsp;&nbsp;&nbsp;I&nbsp;N</text></div></div><br/>
        </div>
        {/* <div className="sidenav-icons2-div"><a href="https://www.facebook.com/angelospizzaharvardillinois/"><img className="sidenav-icons2" alt="icons" src="facebook-sidenav.png"/></a><a href="https://www.instagram.com/explore/locations/375226850/angelos-pizza/"><img className="sidenav-icons2" alt="icons" src="insta-sidenav.png"/></a><a href="tel:18159436458"><img className="sidenav-icons2" alt="icons" src="phone-sidenav.png"/></a></div> */}
        </div><br/><div className="icons-div"><br/>
        <div className="sidenav-icons2-div"><a href="https://www.facebook.com/angelospizzaharvardillinois/"><img className="sidenav-icons2" alt="icons" src="facebook-sidenav.png"/></a><a href="https://www.instagram.com/explore/locations/375226850/angelos-pizza/"><img className="sidenav-icons2" alt="icons" src="insta-sidenav.png"/></a><a href="tel:18159436458"><img className="sidenav-icons2" alt="icons" src="phone-sidenav.png"/></a></div>
      </div>
</div>
        <div className="nav-wide">
        
        <div className="container-div">
        <div className="container">
         <img src="left-grapes.jpeg" alt="grapes" className="grapes"/><img src="grapes2.jpg" className="grapes-left" alt="grapes"/><img onClick={this.openNav.bind(this)} id="hamburger" className="hamburger-menu" src="hamburger.jpg" alt="menu"/>
          <div className="upper-title"><div className="angelos-header"><h5>ANGELO'S</h5></div><br/><div className="credit-card-div"><img className="credit-cards" src="cards.png" alt="Cards we accept"/></div>
          <div id="page-sub-header" className="page-sub-header"><h8>Pizza<h8>&</h8>Italian Cuisine</h8></div></div><div className="login-name-div-responsive" style={{ display: this.state.displaySessionFirst, float: "right" }} ><h10> Welcome, {this.state.sessionFirstName}!</h10></div><br/>
        </div>
        </div>

        <nav className="navbar navbar-expand-lg">

  

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
               <text class="nav-link" onClick={this.menuSelect.bind(this)}>MENU</text>
              </li>
              <li class="nav-item active">
                <a href="https://angelospizzaharvard.hungerrush.com/Order/OrderType" className="nav-link">PLACE ORDER</a>
              </li>
            <li class="nav-item active">
                <text class="nav-link" onClick={this.reserve.bind(this)}>BANQUET ROOMS</text>
                {/* <text class="nav-link" onClick={this.reserve.bind(this)}>BANQUET ROOMS</text> */}
              </li>
              <li class="nav-item">
                <text className="nav-link"><a href="https://angelospizzaharvard.hungerrush.com/Account/Register">CREATE ACCOUNT</a></text>
                <div style={{ display: this.state.displayManageAccount }} ><text class="nav-link" onClick={this.showWelcome.bind(this)}>MANAGE ACCOUNT</text></div>
              </li>
              <li class="nav-item">
                {/* <div style={{ display: this.state.displayLogIn }} ><text class="nav-link" onClick={this.showII.bind(this)}>LOG IN</text></div> */}
                <text className="nav-link"><a href="https://angelospizzaharvard.hungerrush.com/Account/LogOn">LOG IN</a></text>
                <div style={{ display: this.state.displayLogOut }} ><text className="nav-link" onClick={this.logout.bind(this)}>LOG OUT</text></div>
              </li>
            </ul>
          </div>
        </nav>
        </div>
<div className="login-name-div-responsive" style={{ display: this.state.displaySessionFirst, float: "center" }} ><h10> Welcome, {this.state.sessionFirstName}!</h10></div>
      <Modal  //Home Modal
          className="home-class" //this will completely overwrite the default css completely
          style={{background: 'brown', position: 'relative', zIndex: '100000000'}} //overwrites the default background
          containerStyle={{background: 'brown', position: 'relative', zIndex: '100000000'}} //changes styling on the inner content area
          containerClassName="test"
          closeOnOuterClick={true}
          id="home-modal"
          show={this.state.homeShow}
          onClose={this.close.bind(this)}>

            <div id="demo" class="carousel slide carousel-fade" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="meat-lover-vertical.jpg" className="meat-lovers-mobile" id="meat-lovers-mobile" alt="Meat Lover's Pizza"/>
                <img src="meat.jpg" className="meat-lovers-home-vertical" alt="Meat Lover's Pizza"/>
                {/* <img src="meat-vertical.jpg" className="meat-lovers-home-vertical" alt="Meat Lover's Pizza"/> */}
                <img src="meat.jpg" className="meat-lovers-home-full" alt="Meat Lover's Pizza"/>
                <div className="carousel-caption-meat-lovers-mobile">
                <div className="meat-lovers-tablet"><text>Serving<br/>Harvard, Illinois<br/>for over<br/>30 Years</text>
                <br/><br/><br/><br/>
                <button type="button" onClick={this.placeOrder.bind(this)} className="btn btn-outline-danger">O R D E R</button></div></div>
                <div className="carousel-caption-meat-lovers">
                  <div className="meat-lovers-caption"><br/>
                    <div className="harvard-header">A Harvard, Illinois<br/><text className="favorite-harvard">favorite </text>for <br/>over 30 Years!</div>
                    <br/><button type="button" onClick={this.placeOrder.bind(this)} className="btn btn-outline-danger-2">O R D E R</button></div></div>
                    {/* <div className="harvard-header">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;&nbsp;H A R V A R D , &nbsp;I L</div><br/><div className="favorite">Favorite</div>
                  <br/><div className="for-over">F O R&nbsp;&nbsp;&nbsp;O V E R</div><br/><div className="years">30 YEARS</div><br/><h1>_</h1> */}
                  
              </div>
             <div className="carousel-item">
                <img src="menu-mobile.jpg" className="meat-lovers-home-vertical" alt="Chicago"/>
                <img src="menu.jpg" className="meat-lovers-home-full" alt="Chicago"/>
                <div className="carousel-caption">
                  <div className="menu-caption">
                  <div className="menu"><text className="authentic">Authentic</text><br/><text> Sicilian Menu</text></div><br/>
                <button type="button" onClick={this.menuSelect.bind(this)} className="btn btn-outline-danger">M E N U</button><br/>
                </div>
              </div>
              </div>
              <div className="carousel-item">
        
                <img src="party-room-vertical.jpg" className="meat-lovers-home-vertical" alt="Dining Room"/>
                <img src="party-room-fb.jpg" className="meat-lovers-home-full-party" alt="Dining Room"/>
                {/* <img src="party-room.jpg" className="meat-lovers-home-full" alt="Dining Room"/> */}
                <div className="carousel-caption">
                <div className="party-room-caption">
                <img src="balloons.png" className="balloon" alt="baloons"/>

                <h3><text className="reserve-caption">Book a Party Room</text></h3><br/>
                <div className="reserve-hide">
                <button type="button" onClick={this.reserve.bind(this)} className="btn btn-outline-danger">R E S E R V E</button>
                </div>
                <div className="reserve-tablet">
                <button type="button" onClick={this.reserve.bind(this)} className="btn btn-outline-danger">B O O K</button>
                </div>
                <div className="reserve-div"><ul className="reserve-bullets"><li className="reserve-bullets">B I R T H D A Y S</li><li className="reserve-bullets">A N N I V E R S A R I E S</li><li className="reserve-bullets">G R A D U A T I O N S</li><li className="reserve-bullets">B A P T I S M S</li></ul></div>

         
</div>
              </div>  
              </div> 
            </div>

            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon"></span>
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon"></span>
            </a> 

            </div>
      </Modal>
      <Modal  //Mobile Phone Home Modal
          className="mobile-modal" //this will completely overwrite the default css completely
          id="mobile-modal"
          style={{background: 'brown', position: 'relative', zIndex: '1000'}} //overwrites the default background
          containerStyle={{background: 'brown', position: 'relative', zIndex: '1000'}} //changes styling on the inner content area
          containerClassName="test"
          closeOnOuterClick={true}
          show={this.state.homeShow}
          onClose={this.close.bind(this)}>
            <div id="contact-sidebar" className="contact-sidebar">
              <div id="contact-us-menu">
              <div id="button-click7" className="button-click7"><button className="button button7" onClick={this.closeContact}><i className="fas fa-angle-down contact"></i></button></div>
              {/* <img className="down-arrow-contact" onClick={this.closeContact} src="down-arrow.png" alt="down"/><br/><br/> */}
              <br/><br/><br/>
              <text className="contact-method">C O N T A C T&nbsp;&nbsp;&nbsp;U S</text><br/><br/>
              <a href="tel:18159436458"><button className="button">P H O N E</button></a>&nbsp;&nbsp;&nbsp;<button onClick={this.email} className="button">E M A I L</button>
              </div>
            </div>
            <div id="demo2" className="carousel slide carousel-fade" data-ride="carousel">
                {/* <text className="mobile-address">205 E. Front St.<br/>Harvard, Illinois</text> */}
                {/* <div className="delivery-dine"><text className="delivery-text">Delivery | Dine-In | Pickup</text></div> */}
                {/* <div className="delivery-dine"><text className="delivery-text">Dine-In | Delivery | Pick-Up</text></div> */}

                <div id="mobile-title-card" className="mobile-title-card">
                <img className="flag" src="flag-title.jpg" alt="flag"/>
                <text className="mobile-title">Angelo's</text><br/>
                <text className="mobile-title-pizza">Pizza</text><br/>
                <img className="map-img" src="map.png" alt="map"/>
                {/* <text className="mobile-title-of">&</text><br/> */}
              </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
              <div className="container-menu-mobile">
                  <img src="doorway-min.jpg" className="menu-mobile" alt="menu-mobile"/>
                </div>
              </div>
              <div className="carousel-item">
                <img src="tortalini-min.jpg" className="menu-mobile" alt="Chicago"/>
              </div>
              <div className="carousel-item">
                <img src="Stuffed-min.jpg" className="menu-mobile" alt="Chicago"/>
              </div>
              {/* <div className="carousel-item">
                <img src="dining.jpg" className="menu-mobile" alt="Chicago"/>
              </div> */}
              {/* <div className="carousel-item">
                <img src="party-room2.jpg" className="menu-mobile" alt="Chicago"/>
              </div>       */}
              <div className="carousel-item">
                <img src="dining-min.jpg" className="menu-mobile" alt="Chicago"/>
              </div>
              <div className="carousel-item">
                <img src="dining3-min.jpg" className="menu-mobile" alt="Chicago"/>
              </div>
                {/* <a className="carousel-control-prev" href="#demo2" data-slide="prev">
                  <span className="carousel-control-prev-icon"></span>
                </a>
                <a className="carousel-control-next" href="#demo2" data-slide="next">
                  <span className="carousel-control-next-icon"></span>
                </a>  */}
              </div>
              </div>
              {/* <div className="biography">Serving<br/> Harvard, IL, for<br/> over 30 years.</div> */}
              <div className="background-bottom"><text></text></div>

      </Modal>
      </div>
      <div id="main-food-menu" className="main-food-menu">
        <div className="menu-choice-table">
            <div id="main-menu-header" className="main-menu-header">Main Menu</div><div className="main-menu-spacer"></div>
            {this.state.myArray.map(function(item, i){
              return <div key={i} data-choice={item} onClick={() => this.menuChoice(item)} value={item} className="card-main-menu">
              <div key={i} data-choice={item} value={item} className="container-main-menu">
                <text className="main-menu-desc">{item.menu_item_desc.toUpperCase()}</text><br/>
                <div className="main-table-divide"></div>
                {/* <text className="sub-menu-price-desc">{item.sub_menu_option_desc.trim()}</text><br/><br/>
                <div className="table-divide"></div>
                <text className="td-price-price">{item.sub_menu_option_pricing}</text><br/> */}
              </div>
            </div>
            }.bind(this))}<br/><br/><text className="home-click" id="home-click" onClick={this.returnHome.bind(this)}>[HOME]</text><br/><br/><br/><br/><br/><br/><br/><br/><br/>
       </div>
{/* 
      <table>
        <tbody>
            {this.state.myArray.map(function(item, i){
              // console.log('item.row');
              return <tr data-choice={item} onClick={() => this.menuChoice(item)} key={i} value={item}><td><br/></td></tr>
            }.bind(this))}
        </tbody>
       </table> */}
       {/* </div> */}
      
      </div>
      <div id="main-selection-menu" className="main-selection-menu">
      <div className="menu-choice-table">
            <div className="menu-header">{this.state.pick}</div>
            {this.state.menuChoice.map(function(item, i){
              return <div key={i} className="card-sub-menu">
              <div className="container-sub-menu">
                <text className="sub-menu-desc">{item.sub_menu_option.toUpperCase()}</text><br/>
                <text className="sub-menu-price-desc">{item.sub_menu_option_desc.trim()}</text><br/><br/>
                <div className="table-divide"></div>
                <text className="td-price-price">{item.sub_menu_option_pricing}</text><br/>
              </div>
            </div>
            })}<br/><br/><br/><br/><br/><br/>
       </div>
      
      </div>
      {/* <div className="biography">Serving<br/> Harvard, IL, for<br/> over 30 years.</div> */}
      </div>
);
          }
        }

export default Navbar;
