import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import reset from "./components/reset";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useEffect, useState } from 'react';

function App() {
  return (
    <div className="App">
        <Router>
          <div>
          <Navbar/>
          <Route exact path="/resetPassword/:token" component={reset} />
          <Footer/>
          </div>
        </Router>
      </div>
  );
}

export default App;
