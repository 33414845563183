import React, { Component } from "react";
import Modal from 'simple-react-modal';
import axios from 'axios';
import "./reset.css";

class reset extends Component {
  // Setting the component's initial state
  constructor(){
    super()
    this.state = {
      showPasswordReSet: true,
    }};

    closePasswordReSet () {
      this.setState({showPasswordReset: false})
    }

    updatePassword (event) {
      if (this.refs.newPassword.value !== this.refs.newPasswordConfirm.value) {
      alert("Passwords must match.  Please try again.")
      } else {
      event.preventDefault();
      alert(this.props.match.params.token)
      axios.post("/updatePassword", {
        token: this.props.match.params.token,
        newPassword: this.refs.newPasswordConfirm.value
        }).then((response) => {
          alert("Password has been updated!");
          window.location.href="/";
          this.setState({showIII: true})
          console.log(response);
        }).catch((error) => {
          console.log(error.response);
    })};
    }


  render() {
    return (
      <div>
      <Modal  // Re-set Password modal
          className="reset-password-modal" //this will completely overwrite the default css completely
          style={{background: 'bisque', position: 'relative', zIndex: '1000'}} //overwrites the default background
          containerStyle={{background: 'bisque', position: 'relative', zIndex: '1000'}} //changes styling on the inner content area
          containerClassName="test"
          closeOnOuterClick={true}
          show={this.state.showPasswordReSet}
          onClose={this.closePasswordReSet.bind(this)}>
                 <text className="X" onClick={this.closePasswordReSet.bind(this)}>X</text>
            <h6>Update your Password</h6><br/>
            <form>

              <div class="login">
              <label for="psw"><b>New Password</b></label><br/>
                <input type="password" placeholder="Create New Password" ref="newPassword" required/><br/>

                <label for="psw"><b>Confirm New Password</b></label><br/>
                <input type="password" placeholder="Confirm New Password" ref="newPasswordConfirm" required/><br/>

                <button type="submit" onClick={this.updatePassword.bind(this)}><text className="label">U P D A T E</text></button>
                </div>
              </form>
      </Modal>
      </div>
    );
  }
}


export default reset;
